import { Component, OnInit } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd";
import { HttpClient } from "@angular/common/http";
@Component({
  selector: "app-calendar",
  templateUrl: "./pay-history.component.html",
  styleUrls: ["./pay-history.component.scss"],
})
export class PayHistoryComponent implements OnInit {
  public list:any = [];
  public userList:any = [];
  public username = '';
  public loading = false;
  public isAdmin = false;
  constructor(private message: NzMessageService, private http: HttpClient) {}
  ngOnInit() {
    this.getPayHistory();
  }
  /** 获取所有 */
  public getPayHistory() {
    this.loading = true;
    this.http
      .post("./assets/api/sql.php", {
        type: "getPayHistory",
      })
      .subscribe((data: any) => {
        this.loading = false;
        if (data.success) {
          this.list = data.list;
          if(data.isAdmin){
            this.isAdmin = data.isAdmin;
            this.userList = data.userList;
          };
        } else {
          this.message.create("error", data.message);
        }
      });
  }
  /** 查询用户 */
  public getUserPayHistory() {
    if(this.username){
      this.loading = true;
      this.http
        .post("./assets/api/sql.php", {
          type: "getUserPayHistory",
          data:{
            name:this.username
          }
        })
        .subscribe((data: any) => {
          this.loading = false;
          if (data.success) {
            this.list = data.list;
          } else {
            this.message.create("error", data.message);
          }
        });
    }else{
      this.getPayHistory();
    }
  }
  time2local(unixTimestamp){
    if(unixTimestamp){
      return new Date(unixTimestamp-0).toLocaleString();
    }else{
      return '-'
    }
  }

}
